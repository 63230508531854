import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { classNames } from 'react-select/src/utils';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  measures,
  getPageAccessFromState,
  ADMIN,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ItemPage = () => {
  const [itemData, setItemData] = useState();
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    authCheck(state.authData);
    getApi();
  }, []);

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'items?sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [itemCode, setItemCode] = useState('');
  const [itemName, setItemName] = useState('');
  const [measure, setMeasure] = useState('');
  const [minQuantity, setMinQuantity] = useState('');
  const [HSN, setHSN] = useState('');
  const [pickId, setPickId] = useState('');
  const [defaultMeasure, setDefaultMeasure] = useState([]);
  const [available, setAvailable] = useState(0);
  const measureOptions = measures();

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'itemCode':
        setItemCode(event.target.value);
        break;
      case 'itemName':
        setItemName(event.target.value);
        break;
      case 'measure':
        setMeasure(event.target.value);
        break;
      case 'minQuantity':
        setMinQuantity(event.target.value);
        break;
      case 'available':
        setAvailable(event.target.value);
        break;
      case 'HSN':
        setHSN(event.target.value);
        break;
    }
  };

  const handleMeasureChange = (event) => {
    setMeasure(event.value);
    setDefaultMeasure({ label: event.label, value: event.value });
    pullDownReset('measure');
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setAvailable(0);
    setItemCode('');
    setItemName('');
    setMeasure('');
    setDefaultMeasure([]);
    setMinQuantity('');
    setHSN('');
    setPickId('');
    resetRequired();
  };

  const postApi = () => {
    const postData = {
      data: {
        itemCode,
        itemName,
        measure,
        minQuantity,
        HSN,
        available,
      },
    };
    let validate = requireCheck(postData, itemData, 'itemCode', pickId, available);
    if (!validate) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'items' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'items', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editItem = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + 'items/' + id, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSave(pageAccess.edit === 1);
        setItemCode(response.data.data.attributes.itemCode);
        setItemName(response.data.data.attributes.itemName);
        setMeasure(response.data.data.attributes.measure);
        setMinQuantity(response.data.data.attributes.minQuantity);
        setHSN(response.data.data.attributes.HSN);
        setPickId(response.data.data.id);
        setAvailable(+response.data.data.attributes.available);
        setDefaultMeasure({
          label: measureOptions.find((o) => o.value === response.data.data.attributes.measure).label,
          value: response.data.data.attributes.measure,
        });
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteItem = (id) => {
    const removeItem = window.confirm('Would you like to delete this item?');
    if (removeItem) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'items/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editItem(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteItem(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Item',
      selector: (row) => `${row.attributes.itemCode} - ${row.attributes.itemName}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'UOM',
      cell: (row) => <div>{measureOptions.find((o) => o.value === row.attributes.measure).label}</div>,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Min Qty',
      selector: (row) => numFormatter(row.attributes.minQuantity),
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Stock',
      selector: (row) => (row.attributes.available ? row.attributes.available.toFixed(3) : 0),
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'HSN',
      selector: (row) => `${row.attributes.HSN}`,
      sortable: true,
      maxWidth: '20%',
    },
  ];
  const filterColumns = ['itemCode', 'itemName', 'minQuantity', 'HSN'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Item Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Item Master</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Item Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="itemCode"
                      placeholder="Item Code *"
                      onChange={(e) => handleChange(e)}
                      value={itemCode}
                      className="itemCode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Item Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="itemName"
                      placeholder="Item Name *"
                      onChange={(e) => handleChange(e)}
                      value={itemName}
                      className="itemName require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Select UOM <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleMeasureChange(e)}
                    options={measureOptions}
                    id="measure"
                    name="measure"
                    placeholder="Select UOM *"
                    value={defaultMeasure}
                    className="require pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Min Quantity <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="minQuantity"
                      placeholder="Min Quantity *"
                      onChange={(e) => handleChange(e)}
                      value={minQuantity}
                      className="minQuantity require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Item HSN <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="HSN"
                      placeholder="Item HSN *"
                      onChange={(e) => handleChange(e)}
                      value={HSN}
                      className="HSN require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Stock<span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="available"
                      placeholder="Stock *"
                      onChange={(e) => handleChange(e)}
                      value={available}
                      className="available require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    type="button"
                    disabled={!isSave}
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Item Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ItemPage;
